import React, { useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

import RightArrow from '../../../assets/icons/right-arrow.svg'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import Address from '../../../assets/icons/address.svg'
import classes from './Slider.module.scss'

/**
 * Slider component
 **/

const Slider = () => {
  let [imageIsShown, setImageIsShown] = useState(0)

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {fileAbsolutePath: {regex: "/partial/location/"}}
          sort: {
            fields: [frontmatter___order]
            order: ASC
          }
        ) {
          edges {
            node {
              frontmatter {
                country
                address
                image {
                  childImageSharp {
                    fluid(
                      maxWidth: 640, 
                      quality: 80,
                      srcSetBreakpoints: [406, 480, 640]
                    ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }   
                  }
                }
              }
            }
          }
        }
      }
    `)

  const slidesHandler = direction => {
    const { edges } = data.allMarkdownRemark

    if (direction === 'right' && edges.length - 1 > imageIsShown) {
      setImageIsShown(imageIsShown + 1)
    }
    if (direction === 'left' && edges.length - 1 <= imageIsShown) {
      setImageIsShown(imageIsShown - 1)
    }
  }
  const { edges } = data.allMarkdownRemark
  return (
    <BackgroundImage
      fluid={edges[imageIsShown].node.frontmatter.image.childImageSharp.fluid}
      className={classes.FormBackgroundImage}
    >
      <div className={classes.BackgroundImageContent}>
        <div className={classes.HeaderAddressWrapper}>
          <div className={classes.TitleWrapper}>
            <Address/>
            <span className={classes.TitleAddress}>Address</span>
          </div>
          <div className={classes.ArrowWrapper}>
            <LeftArrow
              onClick={() => slidesHandler('left')}
              disabled={imageIsShown === 0} />
            <RightArrow
              onClick={() => slidesHandler('right')}
              disabled={imageIsShown === edges.length - 1} />
          </div>
        </div>
        <div className={classes.AddressWrapper}>
          <p>
            {edges[imageIsShown].node.frontmatter.address}
          </p>
          <p>
            {edges[imageIsShown].node.frontmatter.country}
          </p>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Slider
