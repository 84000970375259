import React from 'react'

import classes from './Message.module.scss'
import Times from '../../../assets/icons/times.svg'
import PaperPlane from '../../../assets/icons/paper-plane.svg'

/**
 * Message component
 **/

const Message = props => {
  const cls = [classes.Message]
  if (props.messageStatus) {
    cls.push(classes.Success)
  } else {
    cls.push(classes.Error)
  }
  return (
    <div
      className={cls.join(' ')}
    >
      {props.messageStatus ? <PaperPlane /> : <Times />}
      <span className={classes.MessageStatus}>
        {props.messageText.title}
      </span>
      <div className={classes.MessageText}>
        {props.messageText.text}
          &nbsp;
        <br/>
        <a href="mailto:">info@bluepes.com</a>
      </div>
      <a
        className={classes.HomeLink}
        onClick={props.close}
      >
        Go home
      </a>
    </div>
  )
}

export default Message
