import React, { Component } from 'react'
import { navigate } from 'gatsby'

import Slider from '../components/UI/Slider/Slider'
import Input from '../components/UI/Input/Input'
import TextArea from '../components/UI/Textarea/Textarea'
import {
  createControl,
  validate,
  validateForm,
  formReset
} from '../utils/form-validator'
import Message from '../components/UI/Message/Message'
import SEO from '../components/seo'

/**
 * Returns form controls
 * @param no needs
 * @returns {objects} Object that contain form controls (name, email, phone, message).
 */

const apiToken = process.env.GATSBY_API_TOKEN
const keyName = '9ba919f984933f323d806ac1f2a30c064a9f9935'
const keyEmail = '7b16d8b439620b2ad7351bde7f5850350bf88d22'
const keyMessage = 'af5b6a7b5fc7938f334204b6408dc852a755a2cd'

const hostName = process.env.GATSBY_HOST_NAME
const path = '/v1/deals?api_token=' + apiToken

function createFormControls () {
  return {
    name: createControl(
      {
        label: 'Name',
        errorMessage: '',
        isOnBlur: false
      },
      {
        validationOnchange: { required: false, maxLength: 50 }
      }
    ),
    email: createControl(
      {
        label: 'E-mail',
        errorMessage: '',
        isOnBlur: true
      },
      {
        validationOnblur:
          {
            required: true,
            email: true,
          },
        validationOnchange: { required: true }
      }
    ),
    phone: createControl(
      {
        label: 'Phone Number',
        errorMessage: '',
        isOnBlur: true
      },

      {
        validationOnblur:
          {
            maxLength: 30
          },
        validationOnchange:
          {
            required: false,
            isDigit: true,
            maxLength: 30
          }
      }
    ),
    message: createControl(
      {
        label: 'Message',
        errorMessage: '',
        isOnBlur: false
      },
      {
        validationOnchange: { required: false, maxLength: 2000 }
      }
    )
  }
}

/**
 * Contact form commponent
 */

class ContactUs extends Component {
  /**
    * @type {object}
    * @property {object} Object that contains modal state.
    * @property {boolean} Validation form.
    * @property {object} Object that contains form controls.
    */
  state = {
    message: {
      status: false,
      content: { title: '', text: '' },
      open: false
    },
    isFormValid: false,
    formControls: createFormControls()
  }

  componentDidMount () {
    Array.from(document.querySelectorAll('.contact-us-link'))
      .map(link => link.addEventListener('click', this.formResetHandler))
  }

  componentWillUnmount () {
    Array.from(document.querySelectorAll('.contact-us-link'))
      .map(link => link.removeEventListener('click', this.formResetHandler))
  }

  /**
    * reset form after click on Contact us item on navigation and
    * Let's work together on footer if user location at contact-us page
    */

  formResetHandler = () => {
    this.setState({
      formControls: formReset({ ...this.state.formControls }),
      isFormValid: false
    })
    if (!this.state.message.open) {
      document.querySelector('textarea').style.height = 'auto'
    } else {
      const message = { ...this.state.message }
      message.open = false
      this.setState({
        message
      })
    }
  }

  /**
    * Returns JSX (input elements).
    * @return {JSX | array} Array of the input elements.
    */

  renderControlsInput () {
    return Object.keys(this.state.formControls).map((controlName, index) => {
      const control = this.state.formControls[controlName]
      if (controlName !== 'message') {
        return (
          <div className="input-container" key={controlName + index}>
            <Input
              label={control.label}
              name={controlName}
              value={control.value}
              valid={control.valid}
              shouldValidate={!!control.validation}
              shouldRequired={control.validation.validationOnchange.required}
              touched={control.touched}
              errorMessage={control.errorMessage}
              onChange={event => this.changeHandler(event.target.value, controlName)}
              onBlur={
                control.isOnBlur ? event => this._onBlur(event.target.value, controlName)
                  : null
              }
            />
          </div>
        )
      }
    })
  }

  /**
    * Returns JSX (textarea).
    * @return {JSX | object} Textarea element.
    */

  renderControlTextArea () {
    const control = this.state.formControls['message']
    return (
      <div className="textarea-container">
        <TextArea
          label={control.label}
          value={control.value}
          valid={control.valid}
          shouldValidate={!!control.validation}
          shouldRequired={control.validation.required}
          touched={control.touched}
          errorMessage={control.errorMessage}
          onChange={event => this.changeHandler(event.target.value, 'message')}
        />
      </div>
    )
  }

  /**
    * handle onblur event
    * @param {srting} Input value.
    * @param {srting} Control name.
    */

  _onBlur = (value, controlName) => {
    const formControls = { ...this.state.formControls }
    const control = { ...formControls[controlName] }
    const { isValid, errorMessage } = validate(value, control.validation.validationOnblur)
    control.valid = isValid
    control.errorMessage = errorMessage

    formControls[controlName] = control

    this.setState({
      formControls,
      isFormValid: validateForm(formControls)
    })
  }

  /**
    * handle change event inputs and textarea
    * @param {srting} Inputs or textarea value.
    * @param {srting} Inputs or textarea name.
    */

  changeHandler = (value, controlName) => {
    const formControls = { ...this.state.formControls }
    const control = { ...formControls[controlName] }
    const { isValid, errorMessage } = validate(value, control.validation.validationOnchange)
    control.touched = true
    control.value = value
    if (controlName === 'phone' && !isValid) {
      control.value = control.value.replace(/\D/g, (str, offset, s) =>
        (s.trim().indexOf('+') === 0 && s.indexOf('+') === offset) || (str === ' ') ? str : ''
      )
    }
    control.valid = isValid
    control.errorMessage = errorMessage

    formControls[controlName] = control

    this.setState({
      formControls,
      isFormValid: validateForm(formControls)
    })
  }

  /**
    * handle submit form event
    * @param {SytheticEvent} event
    */

  sendMessageHandler = event => {
    let position = window.pageYOffset
    const interval = setInterval(() => {
      window.scrollTo(0, position < 25 ? 0 : position)
      position -= 25
      if (position < 0) clearInterval(interval)
    }, 20)
    event.preventDefault()
    const formControls = { ...this.state.formControls }
    if (!this.state.isFormValid) {
      Object.keys(formControls).forEach(controlName => {
        const control = { ...formControls[controlName] }
        const { isValid, errorMessage } = validate(
          control.value,
          {
            ...control.validation.validationOnchange,
            ...control.validation.validationOnblur
          }
        )
        control.valid = isValid
        control.errorMessage = errorMessage
        control.touched = true

        formControls[controlName] = control
      })
      this.setState({
        formControls
      })
    } else {
      const message = { ...this.state.message }
      if (!navigator.onLine) {
        message.status = false
        message.open = true
        message.content = {
          title: 'Could not send',
          text: 'Failed to send your message. Please try later or contact us at'
        }
        this.setState({
          message
        })
        return
      }
      const formControls = { ...this.state.formControls }
      const body = {
        'title': `Bluepes: ${ formControls['name'].value }`,
        [keyName]: formControls['name'].value,
        [keyEmail]: formControls['email'].value,
        [keyMessage]:
          `${ formControls['message'].value } 
          Telephone: ${ formControls['phone'].value.replace(/\s/g, '') }`
      }
      return fetch(hostName + path, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
        body: JSON.stringify(body)
      })
        .then(res => {
          message.open = true
          if ([200, 201].includes(res.status)) {
            message.status = true
            message.content = {
              title: 'Message Sent',
              text: `Thank you for your message! We’ll get back to you shortly.
               If you still have questions, please write to`
            }
          } else {
            message.status = false
            message.content = {
              title: 'Could not send',
              text: `Failed to send your message.Please try later or contact us at`
            }
          }
          this.setState({
            message
          })
        })
        .catch(err => {
          message.status = false
          message.open = true
          message.content = {
            title: 'Could not send',
            text: `Failed to send your message. Please try later or contact us at`
          }
          this.setState({
            message
          })
          // eslint-disable-next-line no-console
          console.error(err)
        })
    }
  }

  /**
   * modal button handler
   * */

  messageCloseHandler = () => {
    navigate('/')
  }

  /**
    * render
    * @return {ReactElement} section
    */

  render () {
    const {
      message: {
        status,
        content,
        open,
      }
    } = this.state
    return (
      <>
        <SEO title="Contact Us | BluePes" keywords={[`bluepes`, `contact us`, `IT`]} />
        <section className="contact-us-page">
          <div className="contact-us-page-wrapper wrapper">
            {open && (
              <Message
                messageStatus={status}
                messageText={content}
                close={this.messageCloseHandler}
              />
            )}
            {!open && (
              <form>
                <div className="form-header">
                  <h1>Let’s work together!</h1>
                  <h2>Send us a message</h2>
                </div>
                <div className="input-wrapper">
                  <fieldset>
                    { this.renderControlsInput() }
                  </fieldset>
                  { this.renderControlTextArea() }
                </div>
                <input
                  type="button"
                  value="Send Message"
                  onClick={this.sendMessageHandler}
                />
              </form>
            )}
            <Slider />
          </div>
        </section>
      </>
    )
  }
}

export default ContactUs
