import React from 'react'
import classes from './Textarea.module.scss'

/**
 * Returns form controls
 * @param {object} Contains field data.
 * @returns {boolean} Is field valid.
 */

function isInvalid ({ valid, touched, shouldValidate }) {
  return !valid && shouldValidate && touched
}

/**
 * Execute auto expand textarea during input text
 */

const autoExpand = e => {
  let { scrollHeight } = e.target

  if (scrollHeight > 221) {
    e.target.style.height = 221 + 'px'
    e.target.style.overflow = 'auto'
  } else {
    e.target.style.overflow = 'hidden'
    e.target.style.height = 'auto'
    e.target.style.height = e.target.scrollHeight + 'px'
  }
}

const TextArea = props => {
  const cls = [classes.TextArea]

  if (isInvalid(props)) {
    cls.push(classes.Invalid)
  }

  if (props.value.trim() !== '') {
    cls.push(classes.NotEmpty)
  }

  return (
    <div
      className={cls.join(' ')}
    >
      <textarea
        rows={1}
        value={props.value}
        onInput={ autoExpand }
        onChange={props.onChange}
      />
      <span className={classes.FloatingLabelTextarea}>
        {props.label}
      </span>
      {
        isInvalid(props)
          ? <div className={classes.ErrorMessage}>
            {props.errorMessage || 'Enter correct value'}
          </div>
          : null
      }
    </div>
  )
}

export default TextArea
