import React from 'react'
import classes from './Input.module.scss'
import Star from '../../../assets/icons/star.svg'

/**
 * Returns form controls
 * @param {object} Contains field data.
 * @returns {boolean} Is field valid.
 */

function isInvalid ({ valid, touched, shouldValidate }) {
  return !valid && shouldValidate && touched
}

const Input = props => {
  const cls = [classes.Input]

  if (isInvalid(props)) {
    cls.push(classes.Invalid)
  }

  if (props.value.trim() !== '') {
    cls.push(classes.NotEmpty)
  }

  return (
    <div className={cls.join(' ')}>
      <input
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        onBlur={props.onBlur}
      />
      <span className={classes.FloatingLabel}>
        {props.label}
        {
          props.shouldRequired && <Star />
        }
      </span>
      {
        isInvalid(props)
          ? <div className={classes.ErrorMessage}>
            {props.errorMessage || 'Enter correct value'}
          </div>
          : null
      }
    </div>
  )
}

export default Input
