// eslint-disable-next-line
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const phoneRegex = /^(\s*\+?)[0-9\s]*$/

/**
 * Returns formcontrols with reset value
 * @param {object}  Contains formcontrols.
 * @returns {object} Formcontrols.
 */

export function formReset (formControls) {
  Object.keys(formControls).forEach(controlName => {
    const control = { ...formControls[controlName] }
    control.value = ''
    control.valid = !control.validation.validationOnchange.required
    control.touched = false
    formControls[controlName] = control
  })
  return formControls
}

/**
 * Returns control
 * @param {object} Contains default config.
 * @param {object} Contains validaton info.
 * @returns {object} With control data.
 */

export function createControl (config, validation) {
  return {
    ...config,
    valid: !validation.validationOnchange.required,
    validation,
    touched: false,
    value: ''
  }
}

/**
 * Returns controls validation
 * @param {string} Value of the field.
 * @param {object} Contains validaton info.
 * @returns {object | boolean} Is controls valid.
 */

export function validate (value, validation = null) {
  let isValid = true
  let errorMessage = ''

  if (!validation) {
    return { isValid, errorMessage }
  }

  // check required field is not empty
  if (validation.required) {
    isValid = value.trim() !== '' && isValid
    if (!isValid) {
      errorMessage = 'This field is required'
      return { isValid, errorMessage }
    }
  }

  // check email format valid
  if (validation.email) {
    isValid = emailRegex.test(value)
    if (!isValid) errorMessage = 'Invalid Email'
  }

  // check is user inputs digits
  if (validation.isDigit) {
    isValid = phoneRegex.test(value)
    if (!isValid) {
      errorMessage = 'Only digits are allowed'
      return { isValid, errorMessage }
    }
  }

  // check maximum allow input length (name, phone, message)
  if (validation.maxLength) {
    isValid = value.length < validation.maxLength && isValid
    if (!isValid) {
      switch (validation.maxLength) {
      case 30:
        errorMessage = 'The maximum number length has 30 digits'
        break
      case 50:
        errorMessage = 'Must be 50 characters or less'
        break
      case 2000:
        errorMessage = 'Must be 2000 characters or less'
        break
      }
    }
  }
  return { isValid, errorMessage }
}

/**
 * Returns form validation
 * @param {object} Contains object of controls.
 * @returns {boolean} Is form valid.
 */

export function validateForm (formControls) {
  let isFormValid = true

  for (let control in formControls) {
    if (formControls.hasOwnProperty(control)) {
      isFormValid = formControls[control].valid && isFormValid
    }
  }

  return isFormValid
}
